<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <CourseGroupForm />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import CourseGroupForm from '@/components/course/CourseGroupForm.vue';
export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    CourseGroupForm
  },
  data() {
    return {
      title: 'Course Group Add',
      items: [
        {
          text: 'Course',
          href: '/'
        },
        {
          text: 'Group',
          href: '/'
        },
        {
          text: 'Group Add',
          active: true
        }
      ]
    };
  }
});
</script>
